import { SET_SITE_DATA,SET_PAGE,SET_USER_DATA,SET_LOADING,SET_MENUS,SET_PRICE_BACK} from "./Action";

const initialState = {
   siteData:{},
   userData:{},
   page:"",
   isLoading:true,
   pricePageBack:false,
   menus:[]
  };

  const reducer=(state=initialState,{type,payload})=>{
    switch (type){
            case SET_SITE_DATA:
                return{ ...state, siteData: payload };
            case SET_PAGE:
                return{ ...state, page: payload };
            case SET_USER_DATA:
                return{ ...state, userData: payload };
                case SET_LOADING:
            return{ ...state, isLoading: payload };
            case SET_MENUS:
            return{ ...state, menus: payload }; 
            case SET_PRICE_BACK:
            return{ ...state, pricePageBack: payload };
        default:
            return state;
    }
  }


  export default reducer