
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './css/sources.css';
import './css/dashboard.css';
import './css/style.css';
import './css/NotFound.css';
import './App.css';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NonAuthRoute from './Component/NonAuthRoute';
import PrivateRoute from './Component/PrivateRoute';
import { shallowEqual, useSelector } from 'react-redux';
let Header=React.lazy(() => import("./Component/Header"))
let Footer=React.lazy(() => import("./Component/Footer"))
let NotFound=React.lazy(() => import("./Component/NotFound"))
let Home=React.lazy(() => import("./View/HomePage/Home"))
let About=React.lazy(() => import("./View/About/About"))
let CMS=React.lazy(() => import("./View/CMS/CMS"))
let Features=React.lazy(() => import("./View/Features/Features"))
let Login=React.lazy(() => import("./View/Login/Login"))
let Register=React.lazy(() => import("./View/Register/Register"))
let Forget=React.lazy(() => import("./View/Forget/Forget"))
let ResetPassword=React.lazy(() => import("./View/ResetPassword/ResetPassword"))
let Pricing=React.lazy(() => import("./View/Pricing/Pricing"))
let Knowledge=React.lazy(() => import("./View/Knowledge/Knowledge"))
let Dashboard=React.lazy(() => import("./View/Dashboard/Dashboard"))
let EditProfile=React.lazy(() => import("./View/Dashboard/EditProfile"))
let ChangePass=React.lazy(() => import("./View/Dashboard/ChangePass"))
let PaymentSuccess=React.lazy(() => import("./View/PaymentSuccess/PaymentSuccess"))
let CancelPayment=React.lazy(() => import("./View/CancelPayment/CancelPayment"))
let RequestDemo=React.lazy(() => import("./View/Dashboard/RequestDemo"))
let Subcriptions=React.lazy(() => import("./View/Dashboard/Subcriptions"))
let Contact=React.lazy(() => import("./View/Contact/Contact"))
function App() {
  const { isLoading} = useSelector(
    (state) => ({
      
      isLoading: state.dataReducer.isLoading,
      

    }),
    shallowEqual
  );
  return (
<>
<div className="App">
      <BrowserRouter basename='/'>
        <Header/>
          <Routes>
          <Route exact path='/' element={<React.Suspense fallback={<>...</>}><Home/></React.Suspense>}/>
          {/* <Route exact path='/about-us' element={<React.Suspense fallback={<>...</>}><About/></React.Suspense>}/> */}
          {/* <Route exact path='/features' element={<React.Suspense fallback={<>...</>}><Features/></React.Suspense>}/> */}
          <Route exact path='/register' element={<React.Suspense fallback={<>...</>}><Register/></React.Suspense>}/>
          <Route exact path='/forget-password' element={<React.Suspense fallback={<>...</>}><Forget/></React.Suspense>}/>
          <Route exact path='/reset-password/:id' element={<React.Suspense fallback={<>...</>}><ResetPassword/></React.Suspense>}/>
          <Route exact path='/pricing' element={<React.Suspense fallback={<>...</>}><Pricing/></React.Suspense>}/>
          <Route exact path='/knowledge-base' element={<React.Suspense fallback={<>...</>}><Knowledge/></React.Suspense>}/>
          <Route exact path='/payment-success' element={<PrivateRoute><React.Suspense fallback={<>...</>}><PaymentSuccess/></React.Suspense></PrivateRoute>}/>
          <Route exact path='/payment-cancellation' element={<PrivateRoute><React.Suspense fallback={<>...</>}><CancelPayment/></React.Suspense></PrivateRoute>}/>
          <Route exact path='/dashboard' element={<PrivateRoute><React.Suspense fallback={<>...</>}><Dashboard/></React.Suspense></PrivateRoute>}/>
          <Route exact path='/edit-profile' element={<PrivateRoute><React.Suspense fallback={<>...</>}><EditProfile/></React.Suspense></PrivateRoute>}/>
          <Route exact path='/change-password' element={<PrivateRoute><React.Suspense fallback={<>...</>}><ChangePass/></React.Suspense></PrivateRoute>}/>
          <Route exact path='/request-demo' element={<PrivateRoute><React.Suspense fallback={<>...</>}><RequestDemo/></React.Suspense></PrivateRoute>}/>
          <Route exact path='/subcriptions' element={<PrivateRoute><React.Suspense fallback={<>...</>}><Subcriptions/></React.Suspense></PrivateRoute>}/>
          <Route exact path='/login' element={<NonAuthRoute><React.Suspense fallback={<>...</>}><Login/></React.Suspense></NonAuthRoute>}/>
          <Route exact path='/contact-us' element={<React.Suspense fallback={<>...</>}><Contact/></React.Suspense>}/>
          <Route exact path='/:id' element={<React.Suspense fallback={<>...</>}><CMS/></React.Suspense>}/>

          <Route path='/*' element={<React.Suspense fallback={<>...</>}><NotFound/></React.Suspense>}/>
          </Routes>
          <Footer/>
        </BrowserRouter>
        <ToastContainer />
    </div>
    {isLoading&&<div className='loaddiv' ><div class="loader"></div></div>}
</>
    
  );
}

export default App;
